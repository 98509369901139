<template>
  <!-- modal start -->
  <div
    class="modal fade type_w700"
    :class="{ show: getters.getModalStatus(props.target) }"
    id="add"
    tabindex="-1"
    role="dialog"
    aria-labelledby="add"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <!-- modal title start -->
        <div class="modal-header">
          <h5 class="modal-title">{{ getters.getModalTitle(target) }}</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <div aria-hidden="true" @click="clickCloseBtn">X</div>
          </button>
        </div>
        <!--// modal title end -->

        <!-- modal body start -->
        <div class="modal-body">
          <div class="inner_box">
            <div
              v-for="(entry, key) in getters.getModalItem(target)"
              :key="`row_${key}`"
            >
              <!-- 기본입력창 Start -->
              <div
                class="h-40"
                v-if="entry.type == 'input' && entry.show && key !== 'address'"
              >
                <label class="type_flex align-items-center">
                  <p class="w-100px pr-10">{{ entry.label }}</p>
                  <div class="flex_item">
                    <input
                      type="text"
                      class="form-control"
                      :value="getters.getModalItem(target)[key].value"
                      :placeholder="entry.label"
                      :disabled="
                        getters.getModalMode(target) === 'read' ||
                        (getters.getModalMode(target) === 'update' &&
                          !entry.editable)
                      "
                      @input="
                        (e) =>
                          store.commit('setModalItem', {
                            target: target,
                            key: key,
                            value: e.target.value,
                          })
                      "
                    />
                  </div>
                </label>
              </div>
              <!-- 기본입력창 End -->

              <!-- 주소검색 Start -->
              <div
                class="mb-8px"
                v-if="entry.type === 'input' && entry.show && key === 'address'"
              >
                <div class="type_label type_flex">
                  <p class="w-100px pr-10 pt-8px">{{ entry.label }}</p>
                  <div class="flex_item">
                    <div class="w-100p row form-group mb-5px">
                      <input
                        type="text"
                        :value="getters.getModalItem(target)[key].value"
                        class="pseudo_input"
                        id="adrress"
                        disabled
                      />
                      <button
                        for="adrress"
                        @click="searchAddress"
                        class="small-btn"
                        :disabled="
                          getters.getModalMode(target) === 'read' ||
                          (getters.getModalMode(target) === 'update' &&
                            !entry.editable)
                        "
                      >
                        검색
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 주소검색 End -->

              <!-- 시/도 SelectBox Start  -->
              <div
                class="h-40"
                v-if="
                  entry.type === 'selectBox' && entry.show && key === 'siDo'
                "
              >
                <label class="type_flex align-items-center">
                  <p class="w-100px pr-10">
                    {{ entry.label }}
                  </p>

                  <div class="flex_item">
                    <select
                      class="form-control"
                      :value="getters.getModalItem(target)[key].value"
                      @input="changeSiDo($event, key)"
                      :disabled="
                        getters.getModalMode(target) === 'read' ||
                        (getters.getModalMode(target) === 'update' &&
                          !entry.editable)
                      "
                    >
                      <option
                        v-for="(siDo, index) in getters.getAreaCode()"
                        :key="`siDo_${index}`"
                        :value="siDo[0]"
                      >
                        {{ siDo[1] }}
                      </option>
                    </select>
                  </div>
                </label>
              </div>
              <!-- 시/도 SelectBox End  -->

              <!-- 시/군/구 SelectBox Start  -->
              <div
                class="h-40"
                v-if="
                  entry.type === 'selectBox' && entry.show && key === 'siGunGu'
                "
              >
                <label class="type_flex align-items-center">
                  <p class="w-100px pr-10">
                    {{ entry.label }}
                  </p>
                  <div class="flex_item">
                    <select
                      class="form-control"
                      :value="getters.getModalItem(target)[key].value"
                      @input="
                        (e) =>
                          store.commit('setModalItem', {
                            target: target,
                            key: key,
                            value: e.target.value,
                          })
                      "
                      :disabled="
                        getters.getModalItem(target)['siDo'].value === '' ||
                        getters.getModalMode(target) === 'read' ||
                        (getters.getModalMode(target) === 'update' &&
                          !entry.editable)
                      "
                    >
                      <option
                        v-for="(siGunGu, index) in getters.getAreaCode(
                          getters.getModalItem(target)['siDo'].value !== ''
                            ? getters.getModalItem(target)['siDo'].value
                            : 1
                        )"
                        :key="`siGunGu_${index}`"
                        :value="siGunGu[0]"
                      >
                        {{ siGunGu[1] }}
                      </option>
                    </select>
                  </div>
                </label>
              </div>
              <!-- 시/군/구 SelectBox End -->

              <!-- 이미지 Start -->
              <div class="mb-8px" v-if="entry.type == 'file'">
                <div
                  v-for="(item, index) in getters.getModalItem(target)[key]
                    .value.length + 1"
                  :key="`file_${index}`"
                >
                  <div
                    class="type_label type_flex"
                    v-if="
                      getters.getModalItem(target)[key].value.length ===
                        index &&
                      !(
                        getters.getModalMode(target) === 'read' ||
                        (getters.getModalMode(target) === 'update' &&
                          !entry.editable)
                      )
                    "
                  >
                    <p class="w-100px pr-10 pt-8px">
                      {{ entry.label + (index + 1) }}
                    </p>
                    <div class="flex_item">
                      <div class="w-100p row form-group mb-5px">
                        <input
                          type="text"
                          class="pseudo_input"
                          readonly
                          value="파일선택"
                        />
                        <label for="addFile" class="small-btn">추가</label>
                        <input
                          type="file"
                          class="form-control-file"
                          id="addFile"
                          @change="addFile($event)"
                          :disabled="
                            getters.getModalMode(target) === 'read' ||
                            (getters.getModalMode(target) === 'update' &&
                              !entry.editable)
                          "
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    class="type_label type_flex"
                    v-else-if="
                      getters.getModalItem(target)[key].value.length !== index
                    "
                  >
                    <p class="w-100px pr-10 pt-8px">
                      {{ entry.label + (index + 1) }}
                    </p>
                    <div class="flex_item">
                      <div class="w-100p row form-group mb-5px">
                        <input
                          type="text"
                          :value="
                            Object.keys(
                              getters.getModalItem(target)[key].value[index]
                            ).includes('fileName')
                              ? getters.getModalItem(target)[key].value[index]
                                  .fileName
                              : getters.getModalItem(target)[key].value[index]
                                  .name
                          "
                          class="pseudo_input"
                          disabled
                        />
                        <label
                          class="small-btn"
                          @click="
                            delFile(
                              getters.getModalItem(target)[key].value[index],
                              index
                            )
                          "
                          >삭제</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--File End -->
            </div>
          </div>
        </div>
        <!--// modal body end -->

        <!-- modal footer start -->
        <div class="modal-footer">
          <button
            v-if="getters.getModalMode(target) === 'create'"
            type="button"
            class="btn btn-primary"
            data-dismiss="modal"
            aria-label="Close"
            @click="clickCreateBtn"
          >
            등록
          </button>
          <button
            v-if="getters.getModalMode(target) === 'read'"
            type="button"
            class="btn btn-primary"
            data-dismiss="modal"
            aria-label="Close"
            @click="clickUpdateBtn"
          >
            수정
          </button>
          <button
            v-if="getters.getModalMode(target) === 'read'"
            type="button"
            class="btn btn-primary"
            data-dismiss="modal"
            aria-label="Close"
            @click="clickDeleteBtn"
          >
            삭제
          </button>
          <button
            v-if="getters.getModalMode(target) === 'update'"
            type="button"
            class="btn btn-primary"
            data-dismiss="modal"
            aria-label="Close"
            @click="clickUpdateSaveBtn"
          >
            저장
          </button>
        </div>
        <!--// modal footer end -->
      </div>
    </div>
  </div>
  <!--// modal end -->
</template>

<script setup>
import { computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const router = useRouter();
const store = useStore();
const getters = computed(() => store.getters);
const emit = defineEmits([
  "clickCreateBtn",
  "clickUpdateSaveBtn",
  "clickDeleteBtn",
]);

const props = defineProps({
  target: {
    type: String,
    required: true,
  },
});

const clickCloseBtn = () => {
  store.commit("setModalMode", { target: props.target, mode: "" }); // 초기화
  store.commit("setModalStatus", { target: props.target, status: false });
  router.go();
};

const clickCreateBtn = () => {
  emit("clickCreateBtn");
};

const clickUpdateBtn = () => {
  store.commit("setModalMode", { target: props.target, mode: "update" });
};

const clickUpdateSaveBtn = () => {
  emit("clickUpdateSaveBtn");
};

const clickDeleteBtn = () => {
  emit("clickDeleteBtn");
};

const changeSiDo = (event, key) => {
  store.commit("setModalItem", {
    target: props.target,
    key: key,
    value: event.target.value,
  });
  store.commit("setModalItem", {
    target: props.target,
    key: "siGunGu",
    value: "",
  });
};

const searchAddress = () => {
  new window.daum.Postcode({
    oncomplete: function (data) {
      console.log(data);
      console.log(data.address);
      store.commit("setModalItem", {
        target: props.target,
        key: "address",
        value: data.address,
      });
    },
  }).open();
};

const addFile = ($event) => {
  if (getters.value.getModalMode(props.target) === "read") return;
  store.commit("addModalShopImagesItem", $event.target.files[0]);
};

const delFile = (item, index) => {
  if (getters.value.getModalMode(props.target) === "read") return;
  store.commit("delModalShopImagesItem", { item: item, index: index });
};
</script>
<style scoped>
.small-btn {
  display: inline-block;
  height: 32px;
  padding: 6px 12px;
  border-radius: 2px;
  border: 1px solid #ddd;
  background-color: #f9f9f9;
  vertical-align: top;

  display: flex;
  align-items: center;
}
</style>
